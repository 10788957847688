<template>
  <div v-if="empleado.tipo" class="mx-auto mt-5">
    <ConfirmDialog
      ref="confirmDialog"
      :dialogText="dialogText"
      :dialogTitle="dialogTitle"
      :dialogYes="dialogYes"
      :dialogNo="dialogNo"
      :id="this.empleado.id"
      @dialog-yes="handleDialogYes"
    ></ConfirmDialog>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <div class="text-center">
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon color="info" @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <img
              v-if="this.foto"
              width="80%"
              :src="this.foto.foto"
              alt="empleado foto"
            />
          </v-card-text>
          <v-divider></v-divider>
        </div>
      </v-card>
    </v-dialog>

    <v-card>
      <v-container>
        <v-alert dense v-if="error" type="error">{{ this.error }}</v-alert>
        <v-row>
          <v-col cols="8">
            <v-card-title class="person-name">
              {{ nombreCompleto }}
            </v-card-title>
            <v-card-subtitle>
              {{ this.empleado.get_tipo_display }}
            </v-card-subtitle>
          </v-col>
          <v-col class="text-right">
            <div class="text-no-wrap photo-container">
              <v-progress-circular
                v-if="this.loading"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-avatar v-else size="80">
                <img
                  v-if="this.foto"
                  :src="this.foto.foto"
                  alt="no img"
                  class="photo-viewport"
                  @click="dialog = true"
                />
                <img
                  v-else
                  src="../assets/no-photo.png"
                  alt="no profile picture"
                />
              </v-avatar>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col>
            <p>
              <strong>Género: </strong>{{ this.empleado.get_genero_display }}
            </p>
            <p>
              <strong>Documento: </strong
              >{{ this.empleado.get_documento_tipo_display }} -
              {{ this.empleado.documento_nro }}
            </p>
            <p>
              <strong>Legajo: </strong
              >{{ this.empleado.legajo ? this.empleado.legajo : "--" }}
            </p>
            <p>
              <strong> Sector: </strong
              >{{
                this.empleado.sector
                  ? this.empleado.sector_display.nombre
                  : "--"
              }}
            </p>

            <p><strong> Codificación Rostro: </strong> {{ this.encodings }}</p>
            <p>
              <strong> Estado: </strong
              >{{ this.empleado.estado ? "Activo" : "Inactivo" }}
            </p>

            <p>
              <strong>Email: </strong
              >{{ this.empleado.email ? this.empleado.email : "--" }}
            </p>
            <p v-if="this.empleado.telefonos.length">
              <strong>Teléfonos: </strong>
            </p>
            <ul>
              <li
                v-for="telefono in this.empleado.telefonos"
                :key="telefono.id"
              >
                {{ telefono.get_tipo_display }}: {{ telefono.numero }}
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :to="'/empleado/' + this.empleado.id + '/editar'"
          ><v-icon left darf>mdi-pencil</v-icon>Editar</v-btn
        >
        <v-btn
          color="primary"
          :to="
            this.foto
              ? '/empleado/' + this.empleado.id + '/enrolar/actualizar'
              : '/empleado/' + this.empleado.id + '/enrolar/nuevo'
          "
          ><v-icon left darf>mdi-camera</v-icon>Enrolar</v-btn
        >
        <v-btn color="error" @click="handleDialog"
          ><v-icon left darf>mdi-delete</v-icon>Eliminar</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import EmpleadosService from "@/services/EmpleadosService.js";
import ConfirmDialog from "./ConfirmDialog.vue";

export default {
  name: "EmpleadoCard",
  components: { ConfirmDialog },
  data() {
    return {
      foto: null,
      loading: true,
      encodings: "",
      dialog: false, // esto es para el modal que muestra la foto en grande
      dialogTitle: "Confirmar Eliminar",
      dialogText: "¿Desea eliminar el empleado?",
      dialogYes: "Eliminar",
      dialogNo: "Cancelar",
      error: "",
    };
  },
  props: {
    empleado: {
      type: Object,
      required: true,
    },
    ingreso: {
      type: Boolean,
      required: false,
    },
  },
  created() {
    this.checkEncodings();
    this.loadProfilePicture();
  },
  methods: {
    checkEncodings() {
      EmpleadosService.getEncoding(this.empleado.id)
        .then((response) => {
          this.encodings = "Sí";
        })
        .catch((error) => {
          this.encodings = "No";
        });
    },
    loadProfilePicture() {
      EmpleadosService.getPersonaFoto(this.empleado.id)
        .then((response) => {
          this.foto = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    handleDialog() {
      this.$refs.confirmDialog.dialog = true;
      this.dialogId = this.empleado.id;
    },
    handleDialogYes(id) {
      EmpleadosService.deleteEmpleado(id)
        .then((response) => {
          this.$router.push({
            name: "Empleados",
          });
        })
        .catch((error) => {
          if (error.response.status === 404)
            this.error = "Error: Registro no encontrado.";
          else if (error.response.status === 405)
            this.error =
              "Error: El registro está protegido. Compruebe que no tenga fichadas ni esté asociado con un dispositivo.";
        });
    },

    handleGenerarBtn() {
      EmpleadosService.postEncoding(this.empleado.id)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    nombreCompleto: function () {
      return this.empleado.nombres + " " + this.empleado.apellidos;
    },
  },
};
</script>

<style scoped>
.person-name {
  word-break: break-word;
}

.person-detail-p {
  font-size: 1.5em;
}

.person-detail-phones {
  font-size: 1.5em;
  margin-left: 30px;
}
</style>

<style>
.photo-container {
  margin-right: 15px;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
</style>
